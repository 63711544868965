* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: 'Encode Sans Expanded', sans-serif;
    overflow: hidden;
}

@font-face {
    font-family: 'SyneRegular';
    src: url('./assets/fonts/Syne-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'SyneBold';
    src: url('./assets/fonts/Syne-Bold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
